var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isInitial || _vm.isFileLoading ? _c('b-form', {
    attrs: {
      "novalidate": ""
    }
  }, [_c('div', {
    staticClass: "dropbox cursor-pointer d-flex justify-content-center align-items-center"
  }, [_c('input', {
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".csv, .xlsx",
      "disabled": _vm.isFileLoading || !_vm.canAccess('employee.importEmployee')
    },
    on: {
      "change": function change($event) {
        _vm.$emit('files-change', $event.target.files);
      }
    }
  }), _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center "
  }, [_c('feather-icon', {
    staticClass: "d-block",
    attrs: {
      "icon": "UploadCloudIcon",
      "size": "32"
    }
  }), _vm.isInitial ? _c('div', [_c('b-button', {
    staticClass: "my-1",
    attrs: {
      "variant": "primary"
    }
  }, [_c('h6', {
    staticClass: "mb-0 text-light"
  }, [_vm._v(" " + _vm._s(_vm.$t('btnAddFileText')) + " ")])]), _c('h6', {
    staticClass: "mb-0 text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('dropFileText')) + " ")])], 1) : _vm._e(), _vm.isFileLoading ? _c('div', [_c('h6', {
    staticClass: "mb-0 text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('fileLoading_1')) + " " + _vm._s(_vm.fileName) + " " + _vm._s(_vm.$t('fileLoading_2')) + " ")])]) : _vm._e()], 1)])]) : _vm._e(), _c('div', [_c('div', {
    staticClass: "w-full m-50 d-flex justify-content-between"
  }, [_c('div', [_c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.canAccess('employee.exportTemplate') ? _c('p', {
    staticClass: "my-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('downloadSampleText_1')) + " "), _c('em', {
    staticClass: "text-primary cursor-pointer",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('download-sample-file-handle');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('downloadSampleText_2')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('downloadSampleText_3')) + " ")]) : _vm._e()])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }