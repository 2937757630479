<template>
  <validation-observer
    #default="{ valid }"
    ref="refFormObserver"
    class="w-100"
  >
    <div
      v-if="isFileSuccess || isImporting"
      class="box d-flex bg-light flex-column justify-content-center align-items-center py-2 px-3"
    >
      <!-- ANCHOR Top table -->
      <div class="d-flex justify-content-between align-items-center w-100 mb-75">
        <h4>
          {{ $t('importValidateHeader_1') }} <em class="text-muted">{{ fileName }}</em> {{ $t('importValidateHeader_2') }}
        </h4>
        <div>
          <b-button
            variant="outline-warning"
            @click="$emit('reset')"
          >
            {{ $t('uploadAgain') }}
          </b-button>
        </div>
      </div>

      <b-overlay
        :show="isImporting"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- SECTION Table data import -->
        <b-table
          sticky-header
          responsive
          show-empty
          :empty-text="$t('noRecordFund')"
          :items="tableData"
          :fields="tableFields"
          no-border-collapse
        >
          <template
            v-for="column in tableFields"
            #[`head(${column.key})`]="data"
          >
            <span :key="column.label">
              {{ $t(`employee.${data.label}`) }}
            </span>
          </template>

          <!-- ANCHOR STT -->
          <template #cell(STT)="data">
            {{ data.index + 1 }}
          </template>

          <!-- ANCHOR first name -->
          <template #cell(Employee_First_Name)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Employee First Name"
              rules="required|max:30"
              mode="eager"
              :vid="`firstName${data.index}`"
            >
              <b-form-input
                :id="`firstName${data.index}`"
                v-model="data.item.firstName"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
              />

              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`firstName${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
                :title="validationContext.errors[0]"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR last name -->
          <template #cell(Employee_Last_Name)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Employee Last Name"
              rules="required|max:30"
              mode="eager"
              :vid="`lastName${data.index}`"
            >
              <b-form-input
                :id="`lastName${data.index}`"
                v-model="data.item.lastName"
                :state="getValidationState(validationContext) ? null : false"
                lazy-formatter
                :formatter="trimInput"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`lastName${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR type -->
          <template #cell(Employee_Type)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Employee Type"
              :rules="`required|isBelongTo:${typeOfEmployeeOptionsFilterToString()}`"
              mode="eager"
              :vid="`type${data.index}`"
            >
              <b-form-input
                :id="`type${data.index}`"
                v-model="data.item.type"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`type${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR booker type -->
          <template #cell(Employee_Booker_Type)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Employee Booker Type"
              mode="eager"
              :vid="`bookerType${data.index}`"
            >
              <b-form-input
                :id="`type${data.index}`"
                v-model="data.item.bookerType"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`bookerType${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR gender -->
          <template #cell(Gender)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Gender"
              :rules="`required|isBelongTo:${genderOptionsToString()}`"
              mode="eager"
              :vid="`gender${data.index}`"
            >
              <b-form-input
                :id="`gender${data.index}`"
                v-model="data.item['Gender']"
                lazy-formatter
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`gender${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR username -->
          <template #cell(Username)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Username"
              :rules="`required|min:4|max:16|userName|isUnique:employee,username|duplicateImport:${usernameList.toString()}`"
              mode="eager"
              :vid="`username${data.index}`"
            >
              <b-form-input
                :id="`username${data.index}`"
                v-model="data.item['Username']"
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
                :debounce="300"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`username${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR email -->
          <template #cell(Email_Address)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Email"
              :rules="`required|email|isUnique:employee,email|duplicateImport:${emailList.toString()}`"
              mode="eager"
              :vid="`emailAddress${data.index}`"
            >
              <b-form-input
                :id="`emailAddress${data.index}`"
                v-model="data.item['Email Address']"
                :formatter="trimInput"
                :state="getValidationState(validationContext) ? null : false"
                :debounce="300"
              />
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`emailAddress${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR phone number -->
          <template #cell(Phone_Number)="data">
            <validation-provider
              #default="validationContext"
              immediate
              name="Phone Number"
              :rules="`required|phoneNumber|isUnique:employee,phonenumber|duplicateImport:${phoneList.toString()}`"
              mode="eager"
              :vid="`phoneNumber${data.index}`"
            >
              <b-form-input
                :id="`phoneNumber${data.index}`"
                v-model="data.item['Phone Number']"
                :formatter="trimInput"
                :debounce="300"
                :state="getValidationState(validationContext) ? null : false"
              />
              <!-- maxlength="10" -->
              <b-tooltip
                v-if="validationContext.errors[0]"
                :target="`phoneNumber${data.index}`"
                triggers="hover focus"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                <h6 class="text-white py-25 mb-0">
                  {{ validationContext.errors[0] }}
                </h6>
              </b-tooltip>
            </validation-provider>
          </template>

          <!-- ANCHOR action -->
          <!-- <template
            #cell(action)="data"
          >
            <div class="d-flex justify-content-center align-item w-100">
              <feather-icon
                icon="EditIcon"
                size="20"
                style="cursor: pointer"
                stroke-width="2"
                name="edit"
                class="action-button-custom-class w-100 h-100 px-1 py-50"
                @click="toggleRowEditable(data.index)"
              />
              <feather-icon
                icon="TrashIcon"
                size="20"
                style="cursor: pointer"
                stroke-width="2"
                name="delete"
                class="action-button-custom-class w-100 h-100 px-1 py-50"
                @click="deleteRowHandle(data.index)"
              />
            </div>
          </template> -->
        </b-table>
        <!-- !SECTION -->
      </b-overlay>
    </div>

    <!-- ANCHOR BUTTON ACTION -->
    <div class="w-full m-50 d-flex justify-content-center">
      <b-button
        variant="secondary"
        class="mr-1"
        @click.prevent="$emit('hide-modal')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        class="ml-1"
        :variant="valid ? 'primary':'outline-secondary'"
        :disabled="(!valid) || !canAccess('employee.importEmployee') || isImportFailed"
        @click.prevent="$emit('import-file', tableData)"
      >
        {{ $t('import') }}
      </b-button>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BTable, BFormInput, BTooltip, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, computed } from '@vue/composition-api'

import { typeOfEmployeeOptionsFilterToString, genderOptionsToString } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import {
  required, min, email, isUnique, isBelongTo, duplicateImport, userName,
} from '@validations'

export default {
  components: {
    BButton,
    BTable,
    BFormInput,
    BTooltip,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isFileSuccess: {
      type: Boolean,
      default: false,
    },
    isImportFailed: {
      type: Boolean,
      default: false,
    },
    isImporting: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: '',
    },
    dataImport: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const tableFields = [
      { label: 'STT', key: 'STT' },
      { label: 'Employee_Last_Name', key: 'lastName' },
      { label: 'Employee_First_Name', key: 'firstName' },
      { label: 'Employee_Type', key: 'type' },
      { label: 'Employee_Booker_Type', key: 'bookerType' },
      { label: 'Gender', key: 'gender' },
      { label: 'Username', key: 'username' },
      { label: 'Email_Address', key: 'emailAddress' },
      { label: 'Phone_Number', key: 'phoneNumber' },
    ]

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const tableData = toRefs(props).dataImport

    // List for handle validate duplicate
    const usernameList = computed(() => tableData.value.map(ele => ele.Username))
    const emailList = computed(() => tableData.value.map(ele => ele['Email Address']))
    const phoneList = computed(() => tableData.value.map(ele => ele['Phone Number']))

    // const deleteRowHandle = index => {
    //   tableData.value.splice(index, 1)
    // }

    return {
      // validateTableData,
      // deleteRowHandle,
      tableFields,
      tableData,
      // rowEditableFlag,
      // toggleRowEditable,

      // validation
      refFormObserver,
      getValidationState,
      resetForm,

      typeOfEmployeeOptionsFilterToString,
      genderOptionsToString,

      required,
      min,
      email,
      isUnique,
      isBelongTo,
      userName,
      duplicateImport,

      trimInput,
      usernameList,
      emailList,
      phoneList,
    }
  },
}

</script>

<style lang="scss" scoped>
.box {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  padding: 30px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  border-radius: 4px;
  background: #161d31;
  height: 100%;
}

.action-button-custom-class{
  &:hover{
    background: rgba(22, 29, 49, 0.4);
    border-radius: 8px;
    color: white
  }
}

</style>

<style lang="scss">
</style>
