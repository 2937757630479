var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFileFailed ? _c('div', {
    staticClass: "box bg-light d-flex flex-column justify-content-center align-items-center"
  }, [_c('div', [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('loadFailText_1')) + " "), _c('em', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.fileName))]), _vm._v(" " + _vm._s(_vm.$t('loadFailText_2')) + " ")]), _c('span', [_vm._v(_vm._s(_vm.importError))])]), _c('b-button', {
    staticClass: "mt-75",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tryAgain')) + " ")])], 1) : _vm._e(), _vm.isImportSuccess ? _c('div', {
    staticClass: "box d-flex flex-column justify-content-center align-items-center"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('importCompleted')) + " ")]), _c('div', {
    staticClass: "w-100 px-3"
  }, [_c('b-row', {
    staticClass: "py-50"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('feather-icon', {
    staticClass: "d-block text-success",
    attrs: {
      "icon": "CheckIcon",
      "size": "32"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importResponse.insertedItems ? _vm.importResponse.insertedItems.length : 0) + " " + _vm._s(_vm.$t('importDoneText')) + ".")])])], 1), _vm.importResponse.failedItems.length > 0 ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('feather-icon', {
    staticClass: "d-block text-danger",
    attrs: {
      "icon": "XIcon",
      "size": "32"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex flex-column justify-content-center",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.importResponse.failedItems ? _vm.importResponse.failedItems.length : 0) + " items failed to import:")]), _c('b-table', {
    staticClass: "mt-50",
    attrs: {
      "items": _vm.importResponse.failedItems
    }
  })], 1)], 1) : _vm._e()], 1)]) : _vm._e(), _vm.isImportFailed ? _c('div', {
    staticClass: "box d-flex flex-column justify-content-center align-items-center"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('importFailed')) + " ")]), _c('span', [_vm._v(_vm._s(_vm.importError))]), _c('b-button', {
    staticClass: "mt-75",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tryAgain')) + " ")])], 1) : _vm._e(), _c('div', [_c('div', {
    staticClass: "w-full m-50 d-flex justify-content-between align-items-center"
  }, [_c('div', [!_vm.isImportSuccess ? _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]) : _vm.isImportSuccess ? _c('p', {
    staticClass: "my-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('importSuccess_1')) + " "), _c('em', {
    staticClass: "text-primary cursor-pointer",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.downloadRecentlyImportedFileHandle($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('importSuccess_2')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('importSuccess_3')) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.isImportSuccess ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('hide-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('done')) + " ")]) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }